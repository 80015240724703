@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';



















#q-app {
  font-family: 'Nunito', sans-serif;
  font-size: .875rem;
  @media (min-width: $breakpoint-sm-min) {
    font-size: 1rem;
  }
  font-weight: 300;
}

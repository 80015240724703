.apid-text-h1 {
  font-size: 1.2rem;
  font-weight: 700;
}
@media (min-width: 600px) {
  .apid-text-h1 {
    font-size: 1.5rem;
  }
}

.apid-text-h3 {
  font-size: 0.875rem;
  font-weight: 600;
}
@media (min-width: 600px) {
  .apid-text-h3 {
    font-size: 1rem;
  }
}

.apid-green {
  color: #19bc9b;
}

.apid-red {
  color: #ef5656;
}

.apid-bg-green {
  background-color: #19bc9b;
}

.apid-bg-red {
  background-color: #ef5656;
}

.apid-btn {
  height: 3rem;
}

.loader,
.loader:before,
.loader:after {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
}

.hour-glass {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background-color: black;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 50%, 100% 100%, 0% 100%, 50% 50%);
  clip-path: polygon(0% 0%, 100% 0%, 50% 50%, 100% 100%, 0% 100%, 50% 50%);
  overflow: hidden;
  animation: spin 4s infinite ease-in-out;
}
.hour-glass:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100px;
  height: 50%;
  background-color: #eee;
  animation: slide 4s infinite ease-in-out;
}

.loader.box-rotation {
  transform-origin: center center;
  color: black;
  width: 18px;
  height: 18px;
  border: 1px solid #444;
  position: relative;
  display: inline-block;
  animation: rect-rotate 2s linear infinite;
}
.loader.box-rotation::after {
  content: "";
  height: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background: black;
  opacity: 0.5;
  animation: fill-rect 2s linear infinite;
  -webkit-animation: fill-rect 2s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(100%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rect-rotate {
  0% {
    transform: rotate(0);
  }
  50%, 100% {
    transform: rotate(360deg);
  }
}
@keyframes fill-rect {
  0%, 50% {
    height: 0px;
  }
  100% {
    height: inherit;
  }
}
@media (max-width: 599px) {
  .xs\:mt-4 {
    margin-top: 1rem;
  }
}